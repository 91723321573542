  .robot-header {
    padding: 0px 40px;
    border: 1px solid #f3f3f3;
    color: var(--primary-color);
  }

  .card {
    width: auto;
    min-height: 5vh;
    background-color: rgba(255, 255, 255, 0.849);
    padding: 8px;
    margin: 35px 0;
  }

  .centerContent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .myrobot_main {
    display: flex;
    justify-content: center;
  }

  .prebtn,
  .nextbtn {
    background-color: var(--primary-color);
    font-size: 25px;
  }
.mysubrobot{
  padding: 20px;
  background: #fff;
    position: relative;
}
.subcard{
  position: relative;
  width: 360px;
  height: 535px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 15px;
  border: 3px solid transparent;
}
.sub_card_main {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 16px;
  gap: 16px;
  padding: 16px;
  justify-items: center;
}
.mysubrobot .p-absolute{
  bottom: 20px;

}
@media screen and (min-width:1442px) {
  .sub_card_main {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
  .card_main {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
  .backtest_table {
    width: 100vw !important;

}
}
@media screen and (max-width:1440px) {

  .store {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  .store .card-custom {
    width: 345px;
  }
  .store .card-custom img {
    width: 310px;
  }
  .backtest_table {
    width: 100vw !important;

}
}
@media screen and (min-width:1800px) {
 
  .backtest_table {
    /* width: 100vw !important; */

}
}
@media screen and (max-width:1024px) {
  .sub_card_main {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
  .card_main {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
  .p-absolute{
    position: relative;
  }
  .table_pannel {
    width: 750px;

}
 
}
