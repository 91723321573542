.fill-height {
  height: 92vh;
}
.blocklySvg {
  min-height: 100% !important;
}

.main_wcp{
  margin-left: 200px;
}
.option-style {
  display: flex;
  line-height: 50px;
  justify-content: space-between;
  flex-direction: row;
}
.right_drawer{
  flex: 1;
}
.dropdown-wp {
  border: 1px solid #001338 !important;
  margin-bottom: 25px;
}
.dropdown-wp .ant-select-selector{
  width: 250px !important;
  height: 50px !important;
  background: #F1F1F8 !important;
}

.blocklyToolboxDiv {
  left: -206px !important;
  padding-left: 30px !important;
  min-height: 100% !important;
  padding-top: 2%;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04);

}
.blocklyFlyout {
  left: -210px !important;
}
.injectionDiv {
  overflow: unset !important;
}
.workspace {
  width: 72vw;
  /* margin-top: 25px; */
}

.workspace-header {
  padding: 25px 20px 0;
  border: 1px solid #f3f3f3;
  color: var(--primary-color);
}

.download-image {
  width: 15px;
  padding-left: 3px;
}

.save-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.property-header {
  background: var(--primary-color);
  padding: 5px 24px;
  font-size: 15px;
  color: #fff;
}

.property-body {
  padding: 0px 24px;
  overflow-y: auto;
}

.right_drawer{
  background-color: #fff;
   width: 200px;
   margin-left: 15px;
}
.wcp-btn{
  margin-top: 15px;
  width: 250px;
  height: 50px;
  cursor: pointer;
}
.load-btn{
  margin-top: 15px;
  width: 250px;
  height: 50px;
  background: var(--primary-color);
  color: #fff;
}
.wcp_btn{
  width: 250px;
  height: 50px;
  display: flex;
  align-items: center;
  background: var(--primary-color);
  padding: 0;
  border: 0;
  cursor: pointer;
  margin-bottom: 15px;
}
.wcp_btn div{
  width: 44px;
  background: #404E6A;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wcp_btn img{
  width: 24px;
  height: 24px;   
}
.wcp_btn span{
  color: #fff;
  font-family: var(--font-family-head-);
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: -0.02em;
  text-align: center;
  flex: 1;
}
.wcp_btn_group{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.small_wcp{
  display: none;
}
.small_wcp .workspace-header{
  display: flex;
  border: unset;
  padding: 0px;
  gap: 15px;
}
.small_wcp  .dropdown-wp .ant-select-selector{
  width: 150px !important;
  height: 40px !important;
}
.small_wcp  .dropdown-wp {
  margin: unset;
}
.small_wcp .wcp_btn_group{
  display: flex;
  flex-direction: unset;
  gap: 5px;
}
.small_wcp .wcp_btn_group .wcp-btn{
 margin-top: unset;
}
.small_wcp .wcp_btn_group .wcp_btn{
 margin: unset !important;
 width: 110px;
 height: 40px;
}
.small_wcp .wcp_btn_group .wcp_btn div{
  width: 35px;
}

@media screen and (max-width:1441px) {
  .right_drawer{
    display: none;
  }
  .blocklyToolboxDiv {
    left: unset !important;
    padding-left: 30px !important;
    min-height: 100% !important;
    padding-top: 2%;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  
  }
  .workspace {
    width: 100vw;
    /* margin-top: 25px; */
  }
  .blocklyFlyout {
    left: 0px !important;
  }
  .main_wcp{
    margin-left: unset;
  }
  .small_wcp{
  display: flex;
  }
  .small_wcp{
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    width: 100%;
    justify-content: space-between;
  }
 
  
}
@media screen and (max-width:1000px) {
  .small_wcp{
    justify-content: center;
    gap: 15px;
  }
  
}
@media  (min-width:1441px){
  .main_wcp {
    margin-left: 160px;
}
.blocklyToolboxDiv {
  left: -166px !important;
}
.blocklyFlyout {
  left: -172px !important;
}
}

.blocklyTreeSeparator{
  display: none !important; 
}
.ant-select-selection-search-input{
  margin-top: 10px !important;
}