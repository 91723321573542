.trade_main {
    background: #fff;
    padding: 25px;
    margin: 20px;
    border: 1px solid rgba(230, 239, 245, 1);
    width: 98%;
    height: 1163px;

}
.create_trade_main {
    background: #fff;
    padding: 25px;
    margin: 20px;
    border: 1px solid rgba(230, 239, 245, 1);
    width: 1630px;
    height: 650px;

}

.top_trade {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top_trade h3 {
    font-family: var(--font-family-head-);
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    text-align: left;
    margin-bottom: 0;
}

.top_trade button {
    display: flex;
    align-items: center;
    padding: 0;
    background: var(--primary-color);
    color: #fff;
    cursor: pointer;
}

.top_trade button p {
    font-family: var(--font-family-head-);
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: center;
    padding: 0 15px;
}

.trade_card {
    width: 363.36px;
    height: 455px;
    padding: 20px 18.36px 20px 18px;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);

}
.c_top{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid rgba(230, 239, 245, 1);
    padding-bottom: 15px;
}
.c_detail span,
.c_head span{ 
    font-size: 18px;
    letter-spacing: -0.02em;
    text-align: left;
    margin: 0;
}
.c_detail p, 
.c_head p{ 
    font-family: var(--font-family-head-);
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.02em;
    text-align: left;
    
}
.c_text{
    display: flex;
    align-items: center;
}
.c_text img{
    width: 19.5px;
    height: 18.94px;
    
}
.c_text p{
    color: var(--secondary-color);
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.02em;
    text-align: right;
}
.c_detail{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    padding:15px 0px;
    border-bottom: 1px solid rgba(230, 239, 245, 1);
}
.btns_trade{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}
.go_trade,
.del_trade{
    background:red;
    display: flex;
    align-items: center;
    border: unset;
    color: white;
    padding: 5px 10px;
    gap: 8px;
    cursor: pointer;
}
.go_trade p,
.del_trade p{
    font-family: var(--font-family-head-);
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.02em;
    text-align: center;
    
}
.go_trade{
    background: var(--primary-color);
}

.create_form{
    width: 50%;
}
.form_create{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}
.bottom_trade{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

}
.option_select{
    display: flex;
}

@media screen and (max-width:1440px) {
    .create_form,
    .create_trade_main{
        width: unset;
    }
}
@media screen and (max-width:768px) {
    .title-name{
        display: none;
    }
}
@media screen and (min-width:1441px) {

    .create_trade_main{
        width: 85vw;
    }
}




