.fill-container-height {
  min-height: 91.5vh;
}

.robotstore {
  margin: 25px;
  padding: 20px 30px;
  width: auto;
  background-color: #fff;
  position: relative;
}

.robot-header {
  padding: 0px 40px;
  border: 1px solid #f3f3f3;
  color: var(--primary-color);
}

.card {
  width: auto;
  min-height: 5vh;
  background-color: rgba(255, 255, 255, 0.849);
  padding: 8px;
  margin-top: 16px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.dividerHeight {
  height: initial;
}

.title {
  font-weight: 500;
  text-align: center;
  flex-grow: 1;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon-size {
  font-size: 20px;
  background: var(--primary-color)eb;
  color: white;
  font-weight: 00;
  border-radius: 100%;
  padding: 8px;
}

.cardRatio {
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.btns {
  font-weight: 600;
  color: #fff;
}

.btns:hover {
  background: var(--ternary-color) !important;
  color: var(--primary-color) !important;
  border: 1px solid var(--ternary-color) !important;
}

.image-container {
  overflow: hidden;
  /* Hide overflowing content */
  width: 100%;
  /* Set the width of the container */
  height: 400px;
  /* Set the height of the container */
}

.zoom-img {
  width: 100%;
  /* Make sure the image takes 100% width of the container */
  height: 100%;
  /* Make sure the image takes 100% height of the container */
  transition: transform 0.3s ease;
  /* Add a smooth transition effect */
}

.image-container:hover .zoom-img {
  /* transform: scale(1.2); */
  /* Zoom in the image by 20% on hover */
}

h1.ant-typography,
h2.ant-typography,
.ant-typography h1,
.ant-typography h2 {
  margin-bottom: 0 !important;
  font-size: 30px !important;
}

.flexs {
  display: flex;
  align-items: center;
  gap: 1%;
  flex-wrap: wrap;
}

.card_rating {
  width: 370px;
  height: 150px;
  background-color: #fff;
  display: flex;
  margin-top: 10px;
  gap: 2%;
  padding: 10px;

  flex-direction: column;
  box-shadow: 0px 0px 36px 0px rgba(112, 144, 176, 0.15);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.card_rating:hover {
  transform: scale(1.02);
  /* Zoom in the image by 20% on hover */
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary-color);
  font-weight: 900;
}

.ant-tabs-tab:hover {
  color: var(--primary-color);
}

.ant-tabs-ink-bar {
  background: var(--primary-color);
}

.card_main {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(295px, 1fr));
  gap: 16px;
  padding: 16px;
  justify-items: center;
}

.card-custom:hover {
  border: 3px solid transparent;
  /* Ensure the initial border is transparent */
  border-image-source: linear-gradient(136.07deg, #18EA6C 0.9%, #001338 50.05%, #18EA6C 99.21%);
  border-image-slice: 1;
  transition: border 0.2s ease;
  /* Smooth transition */
}

.c_name {
  margin: 10px 0 15px 0;
}

.c_name h3 {
  /* font-family: var(--font-family-head-); */
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: -5px !important;

}

.card-custom {
  position: relative;
  width: 360px;
  height: 370px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 15px;
  border: 3px solid transparent;
}

.p-absolute {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.pagination_card {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 25px;
}

.card-custom.data-content {
  padding: 5px;
}

.c_bottom {
  /* position: absolute;
  bottom: 20px;
  width: 90%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-custom .data-content .buttons {
  height: 34px;
  width: 108px;
}

.rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-custom img {
  width: 321px;
  height: 205px;
  /* opacity: 0px; */
  object-fit: cover;
  /* border-radius: 14px; */
}

.ant-pagination-item-active {
  font-weight: 800;
  background: #fff;
  border-color: var(--primary-color);
}

.ant-pagination-item-active a {
  color: var(--primary-color);
}

.item:hover {
  border-color: var(--primary-color);
}

.item:hover a {
  color: var(--primary-color);
}

.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: var(--primary-color);
}

.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: var(--primary-color);
}

.ant-pagination-item,
.ant-pagination-item-link {
  border-radius: 5px !important;
}

.ant-pagination-item:hover {
  border-color: var(--primary-color);
}

.ant-select-dropdown {
  z-index: 99999;
}

.robotstore .ant-tabs-nav-wrap {
  justify-content: flex-start !important;
}

.ant-tabs-tab {
  font-size: 16px !important;
  color: var(--textColor);
  font-family: var(--font-family-head-);
}

.price_card {
  color: var(--primary-color);
  font-size: 24px !important;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;

}

.price_card span {
  font-size: 16px;
}

.price_card sup {
  margin-right: -4px;
}

.robot_subcription {
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 20px;
  justify-items: center;
  width: auto;

  position: relative;
  margin: 25px;
}

.r-detail {
  padding: 15px;
  display: flex;
  background-color: #fff;
}

.r-detail img {
  width: 420px;
  height: 420px;

}

.description {
  padding: 0 15px;
  position: relative;
  width: 100%;
}

.description .title {
  font-family: var(--font-family-head-);
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: left;

}

.description .title .title-d {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.05px;
  letter-spacing: -0.02em;
  text-align: left;

}

.rating-robot {
  margin-top: 15px;
}

.rating-robot .reviews {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: left;

}

.d-bottom {
  position: absolute;
  bottom: 15px;
  width: 100%;
}

.bottomcontain {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  flex-wrap: wrap;
  gap: 10px;
}

.tile-btn {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.tile-btn h5 {
  font-family: var(--font-family-head-);
  font-size: 32px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 0;

}

.sub-swipper {
  margin-top: 20px;
  background-color: #fff;
  padding: 25px;
}

.swipeables {
  display: flex;
  align-content: center;
  height: max-content;
  width: auto;
  padding-top: 20px;
}

.swip-btn {
  width: 35px;
  height: 35px;
  top: 4px;
  left: 4px;
  gap: 0px;
  opacity: 0px;
  all: unset;
  margin-right: 10px;
}

.swipeables .image-container {
  height: unset !important;

}

.g-left {
  width: 100%;
}

.swipeables .image-set-back {
  border-radius: unset;
  width: 321px !important;
  height: 205px !important;
}

.swipeables .cardRatio {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 15px;
  border-radius: unset;
}

.r_title {
  font-family: var(--font-family-head-);
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: left;

}

.g-right {
  background-color: #fff;
  padding: 25px;
  width: 100%;
}

.card_rating .ant-avatar {
  width: 50px;
  height: 45px;
  font-size: 25px;
  padding-top: 5px;
}

.d-flex {
  display: flex;

}

.r_head h3 {
  font-family: var(--font-family-head-);
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 0;
}

.r_head .star .anticon {
  font-size: 15px;
}

.stars h5 {
font-size: 14px;
font-weight: 700;
line-height: 17.05px;
letter-spacing: -0.01em;
text-align: left;

}

@media (max-width: 1350px) {
  .r-detail{
    flex-wrap: wrap;
  }
  .d-bottom{
    position: unset;
    margin-top: 10px;
  }
  .bottomcontain{
    padding: 0;
  }
}

@media (max-width: 1150px) {
  .r-detail img{
    width: 360px;
    height: 360px;
  }
}