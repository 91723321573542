.backtest_main {
    margin: 30px;
}

.conatiner {
    padding: 30px;
    background: #ffff;
}

.robot_select {
    display: flex;
    gap: 15px;
    align-items: center;
    margin: 20px 0;
}

.robot_select .dropdown-wp {
    margin: 0 !important;
}

.bt_form {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px;
}

.text-input {

    height: 50px !important;
}

.backtest-s {
    gap: 13px !important;
}

.bt_form .indicator-label {
    line-height: 25px !important;
}

.editable_field {
    display: flex;
    gap: 15px;
}

.card_editable {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px;
    width: 300px;
    height: 310px;
    overflow-y: scroll;
  
}
.card_editable h3{
    margin-right: 1em;
    -webkit-user-select: none;
    user-select: none;
    font-weight: 700;
    font-family: var(--font-family-head-);
    font-size: 20px;
    color: var(--secondary-color);
  
}

.card_editable::-webkit-scrollbar {
    width: 6px; /* Set scrollbar width */
}

.card_editable::-webkit-scrollbar-thumb {
    background-color: #57387d; /* Set thumb color */
    border-radius: 10px; /* Optional: for rounded thumb */
}

.card_editable::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Set track color */
}
.top_trade{
    margin-bottom: 20px;
}

.rec_nf{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 450px;
}