.full_main {
    margin: 20px;
}

.full_top {
    display: flex;
    align-items: center;
    gap: 25px;
    flex-wrap: wrap;
}

.full_left {
    flex: 2;
}

.full_right {
    flex: 1;
}

.full_left_top {
    display: flex;
    gap: 25px;
    justify-content: space-between;
}

.full_card {
    width: 248px;
    height: 97px;
    padding: 25px 20px;
    background: #fff;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    gap: 10px;
}

.fc_title {
    font-size: 14px;
    font-weight: 600;
    line-height: 17.05px;
    text-align: left;
    color: rgba(163, 174, 208, 1);
}

.fc_value {
    font-family: var(--font-family-head-);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;

}

.full_right_bottom,
.full_left_bottom {
    padding: 30px 20px;
    background: #fff;
    margin-top: 15px;
}

.full_bottom_card {
    width: 230px;
    height: 200px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
    padding: 25px;

}

.full_bottom_card h3 {
    margin-top: 10px;
    font-family: var(--font-family-head-);
    font-size: 16px;
    font-weight: 650;
    line-height: 19.2px;
    text-align: left;
    color: rgba(163, 174, 208, 1);
}

.full_bottom_card h1 {
    margin-top: 15px;
    margin-bottom: 0;
    font-family: var(--font-family-head-);
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    text-align: left;
}

.full_bottom {
    background: #fff;
    margin-top: 25px;
    padding: 25px;
}

.full_bottom_title {
    display: flex;
    justify-content: space-between;


}

.full_bottom_title h3 {
    font-family: var(--font-family-head-);
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    text-align: left;

}

.bottom_main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1.5fr 1fr  ;
}

.full_bottom_title button {
    width: 240px;
    height: 50px;
    background: #18EA6C;
    color: var(--primary-color-);
    font-size: 15px;
    font-weight: 700;
}

.detail1 {
    border-right: 1px solid #E6EFF5;
    padding: 0px 50px 0 25px;
}

.detail1 div {
    margin: 30px 0px;
}

.detail1 h3 {
    font-family: var(--font-family-head-);
    font-size: 20px;
    font-weight: 650;
    letter-spacing: -0.02em;
    margin: 0;
}

.detail1 p {
    font-size: 12px;
    font-weight: 600;
    line-height: 17.05px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #A3AED0;
}
.full_left_bottom img{
    max-width: 100%;
}
@media screen and (max-width:1440px) {
    .full_left_top {
        /* justify-content: center; */
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
    }

    .full_left,
    .full_right {
        flex: 0;
    }

    .full_top {
        justify-content: center;
        align-items: unset;
    }

    .bottom_main {
        grid-template-columns: 1fr 1fr 1fr;
    }
    
}